import { LocalStorageKeys, Theme, variantProvider, React, api, AppImages } from '@/app'
import { AppStatusOverlay, Page, View, Link, Button, Text, TextInput, Image } from '@/components'
import { AppStatus, Session, useAppSelector } from '@/redux'
import * as yup from 'yup'
import { onMount, onUpdate, useComponentStyle, useForm, createForm } from '@codeleap/common'
import { APIClient } from '@/services'

export type ContactFormProps = {
  form?: any
  onPressForm?: () => void
}

const ContactForm = (props: ContactFormProps) => {

  const { form, onPressForm } = props

  return (
    <View variants={['column']}>
      <Text text={'Talk to us'} variants={['h2', 'black700', 'bold', 'marginBottom:2']} />
      <Text variants={['black700', 'marginBottom:3']} text={'So... don’t leave us in suspense. How did it go?'} />
      <Text variants={['black700', 'marginBottom:3']} text={'Send us your stories, thoughts, and feedback using this form. Cherry is a new app and we need your opinions to make it better. We promise we’ll never ghost you.'} />
      <View variants={['gap:4', 'column', 'marginBottom:4']} >
        <TextInput {...form.register('name')}
          placeholder={'Louis Brennan'}
          label={'Name'}
        />
        <TextInput {...form.register('email')}
          placeholder={'louis@example.co.uk'}
        />
        <TextInput {...form.register('message')}
          placeholder={"I'd like to suggest"}
          label={'Your message'}
          multiline
          variants={['resize:none', 'multiline']}
        />
      </View>
      <Button variants={['uppercase', 'black']} text={'Send'} onPress={onPressForm} />
    </View>
  )
}

const ContactPage: React.FC = () => {

  const contactForm = createForm('newsletter', {
    name: {
      type: 'text',
      validate: yup.string().required('This cannot be empty'),
    },
    email: {
      type: 'text',
      validate: yup.string().required('This cannot be empty').email('Invalid email'),
    },
    message: {
      type: 'text',
      validate: yup.string().required('This cannot be empty'),
    },
  })

  const form = useForm(contactForm, {
    output: 'json',
    validateOn: 'none',
  })

  const handleSubmitForm = async () => {
    const isValid = form.validateAll(true)

    if (isValid) {
      APIClient.Profile.sendContactUs(form.values)
    }
  }

  return (
    <Page title='Contact Us' headerProps={{ white: true }} >
      <View
        css={styles.wrapper}
        variants={['paddingTop:16', 'gap:8']}
        responsiveVariants={{
          mid: ['column'],
        }}
      >
        <Image responsiveVariants={{ mid: ['center', 'alignCenter', 'justifyCenter', 'flex'] }}
          css={styles.image} objectFit='contain' source={AppImages.ContactBigg} />

        <ContactForm form={form} onPressForm={handleSubmitForm} />
      </View>
    </Page>
  )
}

const HEIGHT = 715
const MID_HEIGHT = 530
const SMALL_HEIGHT = 360

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      // width: '100vw',
      maxWidth: '100%',
      backgroundColor: theme.colors.white,
    },
    text: {
      color: 'black',
    },
    image: {
      height: HEIGHT,
      width: '90%',
      [Theme.media.down('mid')]: {
        height: MID_HEIGHT,
      },
      [Theme.media.down('smallish')]: {
        height: SMALL_HEIGHT,
      },
    },
  }),
  true,
)

export default ContactPage
